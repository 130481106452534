<template>
  <div @click="readMorePressed" class="flex flex-col justify-between cursor-pointer rounded-lg hover:shadow-lg transition duration-200 ease-in-out overflow-hidden bg-white">
    <div>
      <img class="object-cover w-full h-56 md:h-auto" :src="image" alt="Article image">

      <div class="py-8 px-7">
        <div class="text-lg md:text-xl font-semibold mb-2 text-blue-900">{{ title }}</div>
      
        <div class="text-base md:text-lg text-blue-900">
          {{ blurb }}
        </div>
      </div>
    </div>

    <div class="pb-8 px-7">
      <div class="text-sm font-medium text-hp-blue mb-12">{{ category }}</div>

      <button @click="readMorePressed" class="py-4 px-4 flex items-center gap-6 bg-blue-900 hover:bg-blue-900 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
        read more
        <img class="h-6" src="https://img.icons8.com/material-rounded/48/ffffff/right.png"/>
      </button>
    </div>
  </div>
</template>

<script>
const dashify = require("dashify");

export default {
  props: {
    title: String,
    blurb: String,
    category: String,
    image: String
  },
  methods: {
    readMorePressed() {
      this.$router.push(`/blog/${dashify(this.title)}`)
    }
  }
}
</script>