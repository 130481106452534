<template>
  <div class="bg-hp-gray" style="min-height: 100vh;">
    <header class="w-full">
      <Menu class="bg-hp-gray" />
    </header>


    <!-- Main content -->
    <div class="pt-36 pb-80 max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4" style="min-height: 100vh;">
      <div class="text-center text-blue-900 font-bold text-4xl mb-16">
        Blog
      </div>

      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-x-6 gap-y-10">
        <Article 
          title="The Heavy Cost of Deferred Maintenance" 
          blurb="Not all maintenance is created equally, even if it all just looks like dollars and cents..."
          category="Deferred Maintenance"
          :image="require('../../assets/blog/GettyImages-1043560698.jpg')"
        />

        <Article 
          title="Room by Room Home Improvement Projects" 
          blurb="With the realities of the Covid-19 pandemic and the ongoing work from home policies..."
          category="Home Improvement"
          image="https://images.unsplash.com/photo-1595814433015-e6f5ce69614e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        />

        <Article 
          title="How Deferred Maintenance Can Affect a Sale" 
          blurb="Not all maintenance is created equally, even if it all just looks like dollars and cents..."
          category="Deferred Maintenance"
          image="https://images.unsplash.com/photo-1607400201515-c2c41c07d307?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        />
        
        <Article 
          title="A Home Seller's Guide" 
          blurb="Before selling a property, you want to be sure you take all of the necessary steps to prepare both yourself and the home for its debut on the market..."
          category="Selling Your Home"
          image="https://images.unsplash.com/photo-1616588181775-138dc8ba4197?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        />

        <Article 
          title="Consequences of Deferred Home Maintenance" 
          blurb="Home maintenance is an inevitable and tedious part of homeownership. These repairs can be big or small..."
          category="Deferred Maintenance"
          image="https://images.unsplash.com/photo-1570129477492-45c003edd2be?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        />

        <Article 
          title="How to Avoid Deferred Maintenance" 
          blurb="Deferred home maintenance occurs when homeowners do not keep up with routine repairs and improvements..."
          category="Deferred Maintenance"
          image="https://images.unsplash.com/photo-1562259929-b4e1fd3aef09?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        />
      </div>
    </div>


    
    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Menu from "@/components/menu";
import Footer from "@/components/large-footer.vue";
import Article from "@/components/article.vue";

export default {
  components: {
    Menu,
    Footer,
    Article
  },
  methods: {
    goTo(link) {
      window.scrollTo(0, 0);
      this.$router.push(link);
    }
  }
}
</script>


<style scoped>
header {
  widows: 100%;
  position: fixed;
  z-index: 1000;
}
</style>